import logo from './logo.svg';
import './App.css';

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes> {/* Use Routes instead of Switch */}
          <Route path="/" element={
            <>
              <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>Welcome to Aronis Labs</p>
              </header>
            </>
          }/>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
