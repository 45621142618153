import React from 'react';

const PrivacyPolicy = () => (
  <div>
    <h1>Privacy Policy</h1>
    <p>
      Welcome to BeeClub, an application that displays information about nearby bars and nightclubs. 
      This privacy policy will help you understand how we use and protect the data you provide to us when you use our application.
    </p>
    <h2>Data We Collect</h2>
    <p>
      Our application uses your location data to provide information about nearby bars and nightclubs. 
      We do not store or persist any user data, including location data.
    </p>
    <h2>Data Protection</h2>
    <p>
      Since we do not store or persist any user data, there is no need for data protection measures.
    </p>
    <h2>Children's Privacy</h2>
    <p>
      Our application does not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13.
    </p>
    <h2>Contact Us</h2>
    <p>
      If you have any questions or concerns about our privacy policy, you can contact us via our website or through our app store pages.
    </p>
  </div>
);

export default PrivacyPolicy;